import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Slider from "react-slick";
import {
  screen,
  innerWidth,
  mainWhite,
  transHover,
  mainBlack,
  highlight,
} from "../components/common/variables";
import Close from "../images/svg/close-icon.svg";
import { FaAngleRight } from "react-icons/fa";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 80px;
  @media ${screen.small} {
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
  }
  @media ${screen.medium} {
    padding-top: 270px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 120px;
  }
  @media ${screen.xlarge} {
    padding-top: 328px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 180px;
  }

  .directing {
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.small} {
      margin: 0 auto;
    }
    @media ${screen.medium} {
      margin: 0 auto;
    }
    @media ${screen.large} {
      margin: 0 auto;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 600;
      @media ${screen.small} {
        font-size: 2.5rem;
      }
      @media ${screen.xlarge} {
        font-size: 2.7rem;
      }
    }

    &__description {
      margin: 12px 0;
      max-width: 940px;
      @media ${screen.small} {
        margin: 12px 0 24px 0;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: ${highlight};
        text-decoration: underline;
      }

      strong {
        font-weight: 900;
      }

      h1 {
        font-size: 2.2rem;

        margin: 10px 0;
      }
      h2 {
        font-size: 2rem;

        margin: 10px 0;
      }
      h3 {
        font-size: 1.8rem;

        margin: 10px 0;
      }
      h4 {
        font-size: 1.6rem;

        margin: 10px 0;
      }
      h5 {
        font-size: 1.4rem;

        margin: 10px 0;
      }
      h6 {
        font-size: 1.2rem;

        margin: 10px 0;
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;

      .video-item {
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;

        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 270px;
        }
        @media ${screen.xlarge} {
          margin: 10px 0;
          height: 335px;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          background: rgba(37, 61, 95, 0.5);
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          padding-top: 20px;
          padding-left: 20px;
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(255, 124, 80, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-size: 1.2rem;
            position: relative;
            max-width: 440px;
            padding-right: 20px;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
            @media ${screen.xlarge} {
              padding-right: 0;
            }

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }

          .video-subtitle {
            color: ${mainWhite};
            font-size: 0.92rem;
            display: inline-block;
            margin-right: 5px;
            @media ${screen.small} {
              font-size: 1rem;
            }

            &:last-child {
              .video-subtitle--comma {
                display: none;
              }
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          pointer-events: none;
          object-fit: cover;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: " ";
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }
  }
`;

// gallery modal carousel settings
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
};

const GalleryLightBox = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  overflow: scroll;
  @media ${screen.small} {
    padding: 60px;
  }
  @media ${screen.medium} {
    padding: 100px;
  }
  @media ${screen.large} {
    padding: 50px;
  }
  @media ${screen.xlarge} {
    padding: 0;
  }

  .gallery-subcontainer {
    max-width: 1280px;
    width: 100%;
    background: ${mainWhite};
    padding: 8px 8px 16px 8px;
    position: relative;
    @media ${screen.small} {
      padding: 12px 12px 26px 12px;
      max-width: 750px;
    }
    @media ${screen.medium} {
      margin-top: 25px;
      max-width: 800px;
    }
    @media ${screen.large} {
      margin-top: 100px;
      max-width: 940px;
    }
    @media ${screen.xlarge} {
      margin-top: 0;
      max-width: 1280px;
    }

    &__video-container {
      position: relative;
      .video {
        max-width: 1280px;
        min-height: 350px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @media ${screen.small} {
          min-height: 37vw;
        }
      }
    }

    &__texts-container {
      margin-top: 10px;
      padding: 0 6px;
      @media ${screen.small} {
        margin-top: 28px;
      }

      .headings {
        display: flex;
        justify-content: space-between;

        &__title {
          color: ${mainBlack};
          font-size: 1.3rem;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }

        &__year {
          color: ${highlight};
          font-size: 1.3rem;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }
      }

      .descriptions {
        display: none;
        margin-top: 4px;
        @media ${screen.large} {
          display: flex;
          align-items: flex-end;
        }

        h6 {
          color: ${highlight};
          font-size: 0.95rem;
          margin-right: 7px;
          @media ${screen.small} {
            font-size: 1.15rem;
            margin-right: 10px;
          }
        }
      }
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      top: -11px;
      right: -6px;
      background: ${mainWhite};
      border-radius: 50%;
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
      @media ${screen.small} {
        width: 60px;
        height: 60px;
        top: -35px;
        right: -35px;
      }

      svg {
        width: 17px;
        @media ${screen.small} {
          width: 20px;
        }
        .stroke {
          fill: ${mainBlack};
          transition: ${transHover};
        }
        &:hover {
          .stroke {
            fill: ${highlight};
          }
        }
      }
    }

    .prev-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.5));
      cursor: pointer;
      @media ${screen.small} {
        left: 30px;
      }

      svg {
        color: ${mainWhite};
        transform: scale(-1);
        font-size: 2rem;
        @media ${screen.small} {
          font-size: 4rem;
        }
        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }
    }

    .next-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      filter: drop-shadow(2px 1px 1px rgba(0, 0, 0, 0.5));
      cursor: pointer;
      @media ${screen.small} {
        right: 30px;
      }

      svg {
        color: ${mainWhite};
        font-size: 2rem;
        @media ${screen.small} {
          font-size: 4rem;
        }
      }
    }
  }
`;

const CinematographyPage = ({ data }) => {
  // hide/show modal state
  const [showModal, setModal] = useState(false);

  const {
    page_title,
    description,
    project_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const list = useRef(null);

  // controls for next - prev carousel
  const [controls, setControls] = useState(null);

  // image list starter length
  const [currentLength, setCurrentLength] = useState(9);

  // hide gallery modal
  const closeModal = () => {
    setModal(false);
  };

  // show gallery modal
  const openModal = () => {
    setModal(true);
  };

  // load more displayed image list
  // const expandGalleryList = () => {
  //   setCurrentLength(currentLength + 9);
  // };

  // listener for closing the modal by user click and key press
  useEffect(() => {
    if (showModal) {
      const detectTarget = (e) => {
        if (e.target.nodeName === "svg" || e.target.nodeName === "path") {
          return false;
        } else {
          // close modal if user has clicked outside the modal
          if (e.target.className.includes("GalleryLightBox")) {
            closeModal();
          }
        }
      };
      // close modal if user has pressed esc key
      const detectKey = (e) => {
        if (e.keyCode === 27) {
          closeModal();
        }
      };

      window.addEventListener("click", detectTarget);

      window.addEventListener("keydown", detectKey);

      return () => {
        window.removeEventListener("click", detectTarget);
        window.removeEventListener("keydown", detectKey);
      };
    }
  });

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="directing">
          <h1 className="directing__heading">{page_title.text}</h1>
          <div
            className="directing__description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
          <ul className="directing__video-list" ref={list}>
            {project_list.map((item, i) => (
              <li
                key={i}
                className="video-item"
                onClick={() => {
                  openModal();
                  controls.slickGoTo(i);
                }}
              >
                <div className="video-item__texts">
                  <h2 className="video-title">{item.project_title.text}</h2>
                </div>
                <img
                  className="video-item__video"
                  src={item.image.url}
                  alt={item.image.alt}
                />
              </li>
            ))}
          </ul>
        </div>

        <GalleryLightBox style={{ display: showModal ? `flex` : `none` }}>
          <div className="gallery-subcontainer">
            <Slider
              {...settings}
              ref={(c) => {
                setControls(c);
              }}
            >
              {project_list.map((gallery, i) => (
                <div key={i}>
                  <div className="gallery-subcontainer__video-container">
                    <div
                      className="video"
                      style={{ backgroundImage: `url(${gallery.image.url})` }}
                    ></div>
                  </div>
                  <div className="gallery-subcontainer__texts-container">
                    <div className="headings">
                      <h1 className="headings__title">
                        {gallery.project_title.text}
                      </h1>
                      <h2 className="headings__year">
                        {gallery.date && gallery.date.slice(0, 4)}
                      </h2>
                    </div>
                    <div className="descriptions">
                      {gallery.credits && <h6>Credits:</h6>}
                      <p>{gallery.credits}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <span className="close-icon" onClick={closeModal}>
              <Close />
            </span>
            <span
              className="prev-icon"
              onClick={controls && controls.slickPrev}
            >
              <FaAngleRight />
            </span>
            <span
              className="next-icon"
              onClick={controls && controls.slickNext}
            >
              <FaAngleRight />
            </span>
          </div>
        </GalleryLightBox>
      </Wrapper>
    </Layout>
  );
};

export default CinematographyPage;

export const dataQuery = graphql`
  {
    content: prismicHeadshotsPage {
      data {
        page_title {
          text
        }
        description {
          html
        }
        project_list {
          image {
            url
          }
          date
          credits
          project_title {
            text
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
